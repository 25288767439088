<template>
  <b-container fluid>
      <iq-card>
         <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_tim.lesson_wise_trainer_assign') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:body>
              <b-row>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="profession_type"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization_type')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.resource_org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                                <v-select name="profession_type"
                                  v-model="search.profession_type"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= orgTypeList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" v-if="search.profession_type === 1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.resource_org')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.resource_org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                                <v-select name="resource_org_id"
                                  v-model="search.resource_org_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= orgList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="personal_info_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.trainer')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.personal_info_id"
                        :options="trainerList"
                        id="personal_info_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{ trainerLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                                <v-select name="personal_info_id"
                                  v-model="search.personal_info_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainerList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                   <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.organization')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                                <v-select name="org_id"
                                  v-model="search.org_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= orgList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <!-- <b-form-select
                        plain
                        v-model="search.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select> -->
                                <v-select name="training_type_id"
                                  v-model="search.training_type_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingTypeList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_category_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_category')}}
                        </template>
                        <!-- <b-form-select
                          plain
                          v-model="search.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select> -->
                                <v-select name="training_category_id"
                                  v-model="search.training_category_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingCategoryList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <!-- <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select> -->
                                <v-select name="training_title_id"
                                  v-model="search.training_title_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= trainingTitleList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="course_module_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.course_module')}}
                        </template>
                        <!-- <b-form-select
                          plain
                          v-model="search.course_module_id"
                          :options="courseModuleList"
                          id="course_module_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select> -->
                                <v-select name="course_module_id"
                                  v-model="search.course_module_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= courseModuleList
                                  :placeholder="$t('globalTrans.select')"
                                  :filter-by="myFilter"
                                />
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
              </b-row>
          </template>
      </iq-card>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('elearning_tim.lesson_wise_trainer_assign') }} {{ $t('globalTrans.list') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <router-link to="expertise-trainers-form" :class="'btn btn_add_new'">
                      <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                    </router-link>
                  </template>
                  <template v-slot:body>
                      <b-overlay :show="loadingState">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(resource_org_id)="data">
                                        <span v-if="data.item.profession_type === 1">{{ getOrgName(data.item.resource_org_id) }}</span>
                                        <span v-if="data.item.profession_type === 2">{{ $i18n.locale === 'bn' ? data.item.other_org_name_bn : data.item.other_org_name }}</span>
                                        <span v-if="data.item.profession_type === 3">{{ $i18n.locale === 'bn' ? data.item.other_org_name_bn : data.item.other_org_name }}</span>
                                      </template>
                                      <template v-slot:cell(personal_info_id)="data">
                                        {{ $i18n.locale === 'bn' ? data.item.name_bn : data.item.name }}
                                      </template>
                                      <!-- <template v-slot:cell(type_name)="data">
                                      <span class="capitalize">{{ data.item.type_name }}</span>
                                      </template> -->
                                      <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-4 @click="edit(data.item)">
                                            <i class="fas fa-eye"></i>
                                          </a>
                                          <router-link title="Edit" :to="`/training-e-learning-service/tim/expertise-trainers-form?id=${data.item.id}`" class="btn_table_action table_action_edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                              <!-- <pre> trainer Name : {{ getTrainerName(100) }}</pre> -->
                          </b-row>
                      </b-overlay>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
      <b-modal id="modal-4" size="lg" :title="$t('elearning_tim.lesson_wise_trainer_assign') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button @click="pdfExport" class="ml-2 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <b-button @click="pdfExportDownload" class="btn-success water-test-pdf-button" style="margin-right: 80px;">
            <i class="fas fa-print"></i> {{  $t('globalTrans.download') }}
          </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
      </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { allTrainerData, expertiseTrainerList, courseManagmentToggleStatus, courseManagmentFinalSave } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
      return {
        trainerLoading: false,
        search: {
          profession_type: 1,
          resource_org_id: null,
          org_id: null,
          personal_info_id: null,
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          course_module_id: null
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainerNameList: [],
        trainerListData: [],
        trainingTypeList: [],
        courseModuleList: [],
        myFilter: (option, text, search) => {
                  const temp = search.toLowerCase()
                  return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                  option.text_bn.toLowerCase().indexOf(temp) > -1
              }
      }
  },
  computed: {
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // },
    // courseModuleList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
    // },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
    },
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('elearning_config.organization'), class: 'text-center' },
            { label: this.$t('elearning_config.trainer'), class: 'text-center' },
            // { label: this.$t('elearning_config.training_title'), class: 'text-center' },
            // { label: this.$t('elearning_tpm.course_module'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'index' },
              // { key: 'org_name_bn' },
              { key: 'resource_org_id' },
              { key: 'personal_info_id' },
              // { key: 'training_title_bn' },
              // { key: 'course_module_bn' },
              { key: 'action' }
            ]
        } else {
            keys = [
              { key: 'index' },
              // { key: 'org_name' },
              { key: 'resource_org_id' },
              { key: 'personal_info_id' },
              // { key: 'training_title' },
              // { key: 'course_module' },
              { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  async created () {
    this.getOrgTrainerList()
      this.search = Object.assign({}, this.search, {
          org_id: this.$store.state.dataFilters.orgId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
      }
      this.loadData()
  },
  mounted () {
      core.index()
  },
  watch: {
    'search.profession_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 2 || newVal === 3) {
          this.search.resource_org_id = null
          this.trainerListData = []
          this.getOrgTrainerList()
        } else {
          if (this.search.resource_org_id) {
            this.getOrgTrainerList()
          }
        }
      }
    },
    // currentLocale: function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.trainerNameList = this.getOrgTrainerList()
    //   }
    // },
    'search.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_type_id = null
        this.trainingTypeList = this.getTypeList(newValue)
      } else {
        this.trainingTypeList = []
      }
    },
    'search.resource_org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.personal_info_id = null
        this.getOrgTrainerList()
      }
    },
    'search.training_type_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_category_id = null
        this.trainingCategoryList = this.getCategoryList(newValue)
      } else {
        this.trainingCategoryList = []
      }
    },
    'search.training_category_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.training_title_id = null
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      } else {
        this.trainingTitleList = []
      }
    },
    'search.training_title_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.search.course_module_id = null
        this.courseModuleList = this.getCourseModuleList(newValue)
      } else {
        this.courseModuleList = []
      }
    }
  },
  methods: {
    getTrainerName (id) {
      const trainingType = this.trainerList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getOrgName (id) {
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return orgObj !== undefined ? orgObj.text_bn : ''
      } else {
        return orgObj !== undefined ? orgObj.text_en : ''
      }
    },
    getTypeList (orgId) {
      if (this.search.org_id) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === orgId)
        }
        return type
      }
    },
    async getOrgTrainerList () {
      this.trainerLoading = true
      let orgiId = 0
      if (this.$store.state.Auth.activeRoleId !== 1) {
        orgiId = this.$store.state.Auth.authUser.org_id
      }
      const params = {
        org_id: this.search.resource_org_id,
        profession_type: this.search.profession_type,
        applied_org_id: orgiId
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
      if (!result.success) {
        this.trainerListData = []
      } else {
        this.trainerListData = result.data
      }
      this.trainerLoading = false
    },
    finalSave (item) {
      this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseManagmentFinalSave, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    getCategoryList (typeId) {
      if (this.search.training_type_id) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      }
    },
    getTrainingTitleList (categoryId) {
      if (this.search.training_category_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      }
    },
    getCourseModuleList (categoryId) {
      if (this.search.training_title_id) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_title_id === categoryId)
        }
        return trainingTitleList
      }
    },
    dataChange () {
        this.loadData()
    },
    async searchData () {
        this.loadData()
    },
    remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, courseManagmentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
    },
    loadData () {
      const searchData = {
        resource_org_id: this.search.resource_org_id,
        profession_type: this.search.profession_type,
        personal_info_id: this.search.personal_info_id,
        training_type_id: this.search.training_type_id,
        training_title_id: this.search.training_title_id,
        course_module_id: this.search.course_module_id
      }
        const params = Object.assign({}, searchData, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, expertiseTrainerList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getCustomDataList (data) {
      const listData = data.map(item => {
        const trainerNameObj = this.trainerList.find(doc => doc.value === parseInt(item.personal_info_id))
        const trainerNameData = {}
        if (typeof trainerNameObj !== 'undefined') {
          trainerNameData.trainer_name = trainerNameObj.text_en
          trainerNameData.trainer_name_bn = trainerNameObj.text_bn
        } else {
          trainerNameData.trainer_name = ''
          trainerNameData.trainer_name_bn = ''
        }
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.resource_org_id))
        const orgNameData = {}
        if (typeof orgObj !== 'undefined') {
          orgNameData.org_name = orgObj.text_en
          orgNameData.org_name_bn = orgObj.text_bn
        } else {
          orgNameData.org_name = ''
          orgNameData.org_name_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        const OfficeTypeData = {}
        if (typeof OfficeTypeListObj !== 'undefined') {
          OfficeTypeData.office_type = OfficeTypeListObj.text_en
          OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          OfficeTypeData.office_type = ''
          OfficeTypeData.office_type_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        const trainingCategoryData = {}
        if (typeof trainingCategoryObj !== 'undefined') {
          trainingCategoryData.training_category = trainingCategoryObj.text_en
          trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          trainingCategoryData.training_category = ''
          trainingCategoryData.training_category_bn = ''
        }
        const moduleObj = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(courseModuleList => courseModuleList.value === parseInt(item.course_module_id))
        const moduleData = {}
        if (typeof moduleObj !== 'undefined') {
          moduleData.course_module = moduleObj.text_en
          moduleData.course_module_bn = moduleObj.text_bn
        } else {
          moduleData.course_module = ''
          moduleData.course_module_bn = ''
        }
        return Object.assign({}, item, orgNameData, trainerNameData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, moduleData)
      })
      return listData
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    pdfExportDownload () {
      this.$refs.details.pdfExportDownload()
    }
  }
}
</script>
